import React, { Component, useState, useEffect } from 'react';
import logo from './logo.svg';

{/*import './sass/app.scss';*/}

class App extends Component {
  render() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>

    );
  }
}

export default App;