import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

const Dustin = () => {
  const [open, setOpen] = React.useState(false);
  return ( //ref={this.myRef}
    <div data-section-name="dustin" className="thirdSlide" id="dustin">
      <div className={["stort section snap project overlap animate boz", open ? "open" : null].join(" ")}>
        <div className="projecttext">
          <div className="projectmarquee">
            <div className="menu__item">
             <a href="dustin" className="menu__item-link" 
              onMouseEnter={() => { setOpen(!open);}} onMouseLeave={() => { setOpen(!open);}}>
             Dustin</a>
              <div className="marquee">
                <div className="marquee__inner" aria-hidden="true">
                  <span>Dustin</span>
                  <span>Dustin</span>
                  <span>Dustin</span>
                  <span>Dustin</span>
                </div>
              </div>
            </div>
          </div>
          <div className="description side">
            <h4>Augmented reality storytelling</h4>
            <h5>user experience design &amp; visual design</h5>
          </div>
        </div>
        <div className="image-background">
        </div>
        

        {/*<div id="first" className="ball bigger">
          <div className="eye">
               <div className="pupil"></div>
          </div>

          <div className="eyeright">
               <div className="pupil"></div>
          </div>
      </div>*/}


      </div>
    </div>
  );
};




export default Dustin;


