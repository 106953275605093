import React from 'react';
//import './DeStort.scss';

const Program = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="sixthSlide">
    <div data-section-name="program-guide" className={["section snap project program", open ? "open" : null].join(" ")} id="program-guide">
        <div className="projecttext">
          <div className="projectmarquee">
            <div className="menu__item">
              <a href="vpro-program-guide" className="menu__item-link"
              onMouseEnter={() => { setOpen(!open);}} onMouseLeave={() => { setOpen(!open);}}>
              VPRO<br />Program<br />Guide</a>
              <div className="marquee">
                <div className="marquee__inner" aria-hidden="true">
                  <span>VPRO<br />Program<br />Guide</span>
                  <span>VPRO<br />Program<br />Guide</span>
                  <span>VPRO<br />Program<br />Guide</span>
                  <span>VPRO<br />Program<br />Guide</span>
                </div>
              </div>
            </div>
          </div>
          <div className="description side">
            <h4>online tv &amp; radio broadcasting schedule</h4>
            <h5>user experience &amp; user interface design</h5>
          </div>
        </div>
        <div className="image-right image-program small">
        </div>
      </div>
    </div>
  );
};

export default Program;