import React from 'react';
import { Link } from 'react-router-dom';
//import './Hzt.scss';

const Hzt = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="seventhSlide">
      <div data-section-name="hzt" className={["section snap project hzt", open ? "open" : null].join(" ")} id="hzt">
        <div className="projecttext">
          <div className="projectmarquee">
            <div className="menu__item">
              <a href="hzt" className="menu__item-link" 
                onMouseEnter={() => { setOpen(!open);}} onMouseLeave={() => { setOpen(!open);}}>
                Zuidelijk<br />Toneel</a>
              <div className="marquee">
                <div className="marquee__inner" aria-hidden="true">
                  <span>Zuidelijk<br />Toneel</span>
                  <span>Zuidelijk<br />Toneel</span>
                  <span>Zuidelijk<br />Toneel</span>
                  <span>Zuidelijk<br />Toneel</span>
                </div>
              </div>
            </div>
          </div>
          <div className="description side">
            <h4 className="mobile">theatre company website with content-first approach</h4>
            <h5>user experience design</h5>
          </div>
        </div>
        <div className="image-right">
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Hzt;