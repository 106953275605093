import React from 'react';
import { Work } from './views/Work';
import { About } from './views/About';
import { NavBar } from './components/NavBar';
import { DeStortPage } from './views/DeStortPage';
import { ProgramGuide } from './views/ProgramGuide';
import { HztPage } from './views/HztPage';
import { AnimatePage } from './views/AnimatePage';
import { BaltanPage } from './views/BaltanPage';
import { BozPage } from './views/BozPage';
import { PioniersPage } from './views/PioniersPage';
import { DustinPage } from './views/DustinPage';
import { GraphicAnimationsPage } from './views/GraphicAnimationsPage';
import { AnimationsPage } from './views/AnimationsPage';
import { SignalenPage } from './views/SignalenPage';


import { Route, Switch, Redirect } from 'react-router-dom';

export const Routes = () => {
  return (
    <div>
      <NavBar />
      <Switch>
        <Route exact path="/work" component={Work} />
        <Route exact path="/">
          <Redirect to="/work" />
        </Route>
        <Route exact path="/about" component={About} />
        <Route exact path="/vpro-program-guide" component={ProgramGuide} />
        <Route exact path="/de-stort" component={DeStortPage} />
        <Route exact path="/hzt" component={HztPage} />
        <Route exact path="/animate" component={AnimatePage} />
        <Route exact path="/baltan" component={BaltanPage} />
        <Route exact path="/boz" component={BozPage} />
        <Route exact path="/pioniers" component={PioniersPage} />
        <Route exact path="/dustin" component={DustinPage} />
        <Route exact path="/graphicanimations" component={GraphicAnimationsPage} />
        <Route exact path="/animations" component={AnimationsPage} />
        <Route exact path="/signalen" component={SignalenPage} />

      </Switch>
    </div>
  );
};