import React from 'react';

const GraphicAnimationsPage = props => {
  return (
  <div className="de-stort-page graphicanimation">
    <div className="container">
      <h1>Graphic Animations</h1>
        
      <div className="column-left reflect">
        <img src="/images/animations/2.jpg" alt="High contrast band on stage" style={{"max-width":"100%"}} />
      </div>
    </div>

    <div className="vertical-container">
      <div className="vertical-image right">   
          <img src="/images/animations/37.jpg" alt="High contrast band on stage" style={{"max-width":"100%"}} />
      </div>
    </div>

    <div style={{"clear":"both"}} />
    <div className="vertical-container">
      <div className="vertical-image center">   
        <div className="column-right">
          <img src="/images/animations/23.jpg" alt="High contrast band on stage" style={{"max-width":"100%"}} />
        </div>
      </div>
    </div>

    <div className="container mobile-up">
      <div className="column-left bottom-pic">
        <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
          <img src="/images/animations/1.jpg" alt="performer dancing high contrast" />
        </div>
      </div>


      <div className="column-right top">
        <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
          <img src="/images/animations/14.jpg" alt="dancing" />
        </div>
      </div>
    </div>

      <div className="column-left up reflect">
        <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
          <img src="/images/animations/47.jpg" alt="guitar player" />
        </div>
        <div className="top">
          <div className="video-wrapper anim">
            <iframe src="https://player.vimeo.com/video/134894399" title="Truckfighters live band recap" width={560} height={349} frameBorder={0} webkitallowfullscreen mozallowfullscreen allowFullScreen />
          </div>
        </div>
      </div>

      <div className="column-right up">
          <img src="/images/animations/57.jpg" alt="audience at a concert" />
      </div>
      <div style={{"clear":"both"}} />

    <div className="column-right up high z">
        <img src="/images/animations/58.jpg" alt="performer dancing high contrast" />
    </div>

      <div className="column-left mobile-up">
        <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
          <img src="/images/animations/53.jpg" alt="singer at a live concert" />
        </div>
      </div>

      <div className="column-right z">
          <img src="/images/animations/52.jpg" alt="audience at a concert" />
      </div>
      <div style={{"clear":"both"}} />

    <div className="container">
          <div className="column-left">
            <div className="gif">
              <img src="/images/animations/46.gif" alt="portrait"/>
            </div>
          </div>
          <div className="column-right">
            <div className="gif">
              <img src="/images/animations/45.gif" alt="portrait with eyes changing" />
            </div>
          </div>

          <div className=".img-big">
            <img src="/images/animations/35.jpg" alt="concert" />
          </div>

          <div className="column-left">
              <img src="/images/animations/61.jpg" alt="bassplayer"/>
          </div>
          <div className="column-right">
              <img src="/images/animations/62.jpg" alt="singer" />
          </div>

          <div>
            <img src="/images/animations/64.jpg" alt="concert" />
          </div>

          <div className="column-left">
            <div className="gif">
              <img src="/images/animations/48.jpg" alt="portrait"/>
            </div>
          </div>
          <div className="column-right">
            <div className="gif">
              <img src="/images/animations/49.jpg" alt="La Dispute" />
            </div>
          </div>
          <div className="video-wrapper column-left">
            <div className="video-big">
              {/* <a class="work mobileAbout"><div class="caption projectmenu"><h2>About</h2><p>Why, How, What?</p></div></a>    398!*/}
              <iframe width={520} height={360} src="https://www.youtube.com/embed/AeiiP6ZNd1Y?VQ=HD720&rel=0&showinfo=0" title="animation and motion reel" frameBorder={0} allowFullScreen />
            </div>
        </div>

      </div>

      <div className="column-right up" style={{"margin-right":"-40px"}}>
          <img src="/images/animations/44.jpg" alt="portrait image" />
      </div>

          <div className="container">
            <div className="column-left">
              <div className="video-wrapper anim">
                <iframe width={560} height={349} src="https://www.youtube.com/embed/tAdGLwov6pE?VQ=HD720&rel=0&showinfo=0" title="glow animation" frameBorder={0} allowFullScreen />
              </div>
            </div>
            <div className="column-right up high">
              <div className="gif">
                <div>
                  <div style={{"left":"0px","width":"100%","height":"0px","position":"relative","padding-bottom":"99.6%"}}><iframe src="https://giphy.com/embed//l0HlEPm6j7Mq3bHSE/twitter/iframe" title="geometric pattern animation" frameBorder={0} allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" style={{"top":"0px","left":"0px","width":"100%","height":"100%","position":"absolute"}} /></div>
                </div>
              </div>
            </div>

          <div className="column-left">
              <img src="/images/animations/22.jpg" alt="graffity"/>
          </div>
          <div className="column-right up">
              <img src="/images/animations/9.jpg" alt="punk" />
          </div>
          <div className="column-left">
              <img src="/images/animations/60.jpg" alt="band live"/>
          </div>
          <div className="column-right">
              <img src="/images/animations/67.jpg" alt="band live" />
          </div>
          
        </div>


        <div className="img-big">
          <img src="/images/animations/34.jpg" alt="Times Square" />
        </div>

        <div className="container">
          <div className="column-left">
              <img src="/images/animations/6.jpg" alt="skateboarder"/>
          </div>
          <div className="column-right">
              <img src="/images/animations/5.jpg" alt="jump" />
          </div>
          <div className="column-left">
              <img src="/images/animations/10.jpg" alt="Flat"/>
          </div>
          <div className="column-right">
              <img src="/images/animations/27.jpg" alt="Teardown" />
          </div>

        </div>

        <div className="column-left">
          <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
            <img src="/images/animations/41.jpg" alt="windows" />
          </div>
        </div>
        <div className="column-right">
          <img src="/images/skateboarder-animation-no-border-top.gif" alt="skateboarder animation"/>
        </div>

      <div style={{"clear":"both"}} />

        <div className="container">
          <div className="column-left">
            <div className="gif">
              <img src="/images/animations/epldot.gif" alt="psychedelic cell animation green yellow"/>
            </div>
          </div>
          <div className="column-right">
            <div className="gif">
              <img src="/images/animations/eplsquare.gif" alt="psychedelic squares animation" />
            </div>
          </div>
        </div>

        <div className="column-left">
          <div className="video-wrapper" style={{"height":"auto","margin":"40px 0","padding":"0 0"}}>
            <img src="/images/animation-bike3-final.gif" alt="bike in city animation"/>
          </div>
        </div>

        <div className="column-right">
          <div className="gif small">
              <div>
                <div style={{"left":"0px","width":"100%","height":"0px","position":"relative","padding-bottom":"99.6%"}}><iframe src="https://giphy.com/embed//yGkGZmWQcaXni/twitter/iframe" title="organic hypnotic animation" frameBorder={0} allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" style={{"top":"0px","left":"0px","width":"100%","height":"100%","position":"absolute"}} /></div>
              </div>
          </div>
        </div>

        <div className="container">
          <div className="column-left">
            <div className="gif">
              <img src="/images/animations/51.jpg" alt="singer"/>
            </div>
          </div>
          <div className="column-right">
            <div className="gif">
              <img src="/images/animations/50.jpg" alt="singer" />
            </div>
          </div>
          <div className="image-big">
            <img src="/images/animations/65.jpg" alt="concert" />
          </div>
        </div>

        <div className="container">
            <img src="/images/animations/3.jpg" alt="shilhouettes people walking" />
        </div>

        <div className="container">
          <img src="/images/animations/7.jpg" alt="street art" />

          <div className="column-left">
              <img src="/images/animations/20.jpg" alt="graffity door" style={{"max-width":"100%"}} />
          </div>
          <div className="column-right">
            <div className="small-medium">
              <img src="/images/animations/68.jpg" alt="staircase" style={{"max-width":"100%"}} />
            </div>
          </div>

        </div>


        <div style={{"clear":"both"}} />    
        <div className="container">    
          <div className="column-left">
            <img src="/images/animations/38.jpg" alt="door graffity" />
          </div>
        </div>

        <div className="column-right">
          <img src="/images/animations/43.jpg" alt="eye graffity" />
        </div>
        <div style={{"clear":"both"}} />    

        <div className="container">
          <div className="column">
          <img src="/images/animations/69.jpg" alt="movie theatre" />
          </div>
        </div>

        <div className="container">
          <div className="column">
          <img src="/images/animations/42.jpg" alt="neon lights" />
          </div>          
        </div>




        <div className="container">    
          <div className="column-left">
            <img src="/images/animations/55.jpg" alt="yellow light singer" />
          </div>

          <div className="column-right">
            <img src="/images/animations/54.jpg" alt="yellow light guitarist" />
          </div>

          <div className="column-left">
            <img src="/images/animations/71.jpg" alt="entryway" />
          </div>

          <div className="column-right up high">
            <img src="/images/animations/70.jpg" alt="ceiling" />
          </div>
        </div>
        <div style={{"clear":"both"}} />

        <div className="container">            
          <div className="column">
          <img src="/images/animations/12.jpg" alt="windows" />
          </div>
        </div>

        <div style={{"clear":"both"}} />       

        <div className="container">            
          <div className="column-left">
            <img src="/images/animations/72.jpg" alt="jewelery" />
          </div>

          <div className="column-right">
            <img src="/images/animations/25.jpg" alt="girl skeleton shirt" />      
          </div>
  
          <div className="column-left">
            <img src="/images/animations/8.jpg" alt="candy hearts" />
          </div>
        </div>


        <div className="image-big column-right" style={{"width":"65%","float":"right"}}>
          <img src="/images/animations/73.jpg" alt="clouds" style={{"margin":"2rem 2rem 0 0", "float":"right", "width":"100%"}} />
        </div>
        <div className="column-left third">
          <div className="photowrapper">
            <img src="/images/animations/afmsquare.gif" alt="logo animation all future memories" />
          </div>
        </div>

        <div className="container">
          <div className="column">
            <img src="/images/animations/74.jpg" alt="staircase and geometric floor" />
          </div>

            <div className="column-left">
              <img src="/images/animations/76.jpg" alt="wall windows" />
            </div>
            <div className="column-right">
              <img src="/images/animations/75.jpg" alt="pigeon wings" />
            </div>
            <div className="column-left">
              <img src="/images/animations/36.jpg" alt="crowd at a concert" />
            </div>
            <div className="column-right">
              <img src="/images/animations/66.jpg" alt="singer" />
            </div>   
          </div>
 
          <div style={{"clear":"both"}} />       
          <div className="container">
            <div className="column-left">
              <img src="/images/animations/19.jpg" alt="light" />
            </div>
            <div className="column-right">
              <img src="/images/animations/77.jpg" alt="festival lights" />
            </div>    
          </div>
          <div className="container">          
            <div className="column-left">
              <img src="/images/animations/11.jpg" alt="church lights" />
            </div>     
            <div className="column-right">
              <img src="/images/animations/30.jpg" alt="windowlight" />
            </div>  
          </div>
   
    {/*<a className="next" href="boz">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
  */}
  </div>
  );
};

export default GraphicAnimationsPage;