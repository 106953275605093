import React from 'react';

const SignalenPage = props => {
  return (
<div className="de-stort-page boz pioniers">
    <div className="container">
        <h1>Signalen</h1>
        <p className="intro">Reports of nuisance in public domain
        </p>
        <p className="intro-small">How can a citizen easily report a nuisance and how can this be picked up by the practitioner in the system.
        </p>
        <p className="intro-small">Made a proposal for the entire report form in adjustments for better accessibility. Screen reader optimization was a focal point.
        </p>

        <div className="image-big">
            <img src="/images/signalen/stap-1.png" alt="Signalen stap 1 - beschrijf uw melding"/>
        </div>
        <div className="image-big">
            <img src="/images/signalen/stap-2.png" alt="Signalen stap 2 - Wat is de locatie"/>
        </div>


        <div className="middle">
            <h3>New design system report form</h3>
            <p className="description-big">Applied the new design system fundamentals and components to the report form to see if the system and the new style works in the practice of Signals.  
            </p>
        </div>

        <div className="squares">
            <div className="square">
                <img src="/images/signalen/design-system-1.png" alt="new design system implemented in step 1 of the form"/>
            </div>
            <div className="square">
                <img src="/images/signalen/design-system-2.png" alt="new design system implemented in step 2 of the form"/>
            </div>
            <div className="square">
                <img src="/images/signalen/design-system-3.png" alt="new design system implemented in step 3 of the form"/>
            </div>
        </div>
    </div>
    <div className="container">  
        <div className="image-big">
            <img src="/images/signalen/design-system-desktop-1.png" alt="desktop - new design system implemented in step 1 of the form"/>
        </div>
        <div className="image-big">
            <img src="/images/signalen/design-system-desktop-2.png" alt="desktop - new design system implemented in step 2 of the form"/>
        </div>
    </div>


    <div className="container">
        <div className="middle">
            <h3>Location map redesign</h3>
            <p className="description-big">Problem: People continue without clicking on a container. User problem, containers are often difficult to find on the map and it takes a lot of effort to select a container. 
            </p>
            <p className="description-big">Solution: After searching on a address, zoom in much further, so that containers are immediately visible on the map.
Can search immediately by container number. This saves searching on the map. This is also a lot more accessible. 
Being able to search on multiple containers and thus report multiple problems in one report. 
A list view of the nearest containers. Also more accessible than just a map view.
Easily select a container from the list.
</p>
        </div>
    </div>
    <div className="container">
        <div className="squares double">
            <div className="square">
                <img src="/images/signalen/locatiekaart-mock-up-adres.png" alt="location map and list mock-up"/>
            </div>
            <div className="square">
                <img src="/images/signalen/locatie-kaart-mock-up-geselecteerd.png" alt="location map object selected mock-up"/>
            </div>
        </div>
    </div>


    <div className="container">
         <div className="squares wide">
            <div className="square">
                <img src="/images/signalen/locatie-kaart-desktop.png" alt="desktop - location mapa and list visual design"/>
            </div>
            <div className="square mobile">
                <img src="/images/signalen/locatie-kaart-mobiel.png" alt="location map and list visual design"/>
            </div>
        </div>
    </div>


    <div className="frame">
        <div className="inner">
        </div>
        <div className="vertical-image">    
            <img src="/images/signalen/locatie-kaart.gif" alt="scooter animation"/>
        </div>

        
    </div>

    <div className="container">
        <div className="middle">
            <h3>Backoffice</h3>
            <p className="description-big">We get feature requests from Action service centre, team leaders different departments. Integrating into the process is often a challenge, there are many edge cases that you have to take into account
            </p>
            <p className="intro-small">Many subcategories use the same default text. These now have to be created and adjusted manually per subcategory. The wish of editors is to make standard texts global. These must be applicable to multiple subcategories, so that an adjustment only needs to be made in 1 place.
            </p>
        </div>
            <div className="image-big">
                <img src="/images/signalen/standaardtekst-overzicht.png" alt="backoffice default texts overview "/>
            </div>
            <div className="image-big">
                <img src="/images/signalen/standaardtekst-detail.png" alt="backoffice default text detailpage "/>
            </div>
    </div>

    <a className="next" href="pioniers">next<img src="/images/arrow-regular.svg" alt="arrow-forward"/></a>
</div>
  );
};

export default SignalenPage;